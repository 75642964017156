<template>
  <div>
     <b-modal @hidden="resetModal" ref="modal-product-detail" size="xl" centered hide-header hide-footer content-class="overflow-hidden">
        <div class="row align-items-top">
          <div class="col-xl-6">
            <div class="product-detail">
              <b-tabs pills vertical nav-wrapper-class="col-2" content-class="" nav-class="custom-tabs">
                <b-tab class="p-0" v-for="(value, index) in modalDetail.images" :key="index">
                  <template v-slot:title>
                    <img
                      :src="value.image"
                      alt
                      class="img-fluid mx-auto d-block tab-img rounded"
                    />
                  </template>
                  <div class="product-img border rounded">
                    <img
                      :src="value.image"
                      alt
                      class="img-fluid mx-auto d-block rounded"
                    />
                  </div>
                </b-tab>
              </b-tabs>
            </div>
          </div>

          <div class="col-xl-6 text-muted">
            <div class="d-block text-center text-muted my-5" v-if="modalLoading">
                <div class="spinner-border mb-2" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <div>
                Loading..
                </div>
            </div>

            <div v-else>
            <div class="row mb-5 mt-3">
                <div class="col-12" v-if="modalDetail">
                    <h6 id="modal-product-category" class="text-secondary mb-1 d-block fw-normal font-size-14">{{modalDetail.sub_title}}</h6>
                    <h2 class="font-size-22 fw-semibold p-0 mb-2">{{modalDetail.title}}</h2>
                    <div>
                    <div class="rating text-left d-inline-block mb-2">
                    <span v-for="i in 5" :title="modalDetail.rating" :key="i">
                        <i v-if="i <= modalDetail.rating" class="checked text-orange fa fa-star"></i>
                        <i v-else-if="(i-0.5) <= modalDetail.rating" class="checked text-orange fas fa-star-half-alt"></i>
                        <i v-else-if="i > modalDetail.rating" class="far fa-star"></i>
                    </span>
                    </div>
                    <span class="ml-2"> {{parseFloat(modalDetail.rating).toFixed(2)}} ({{modalDetail.reviewer}})</span>
                    </div>
                    
                    <div class="text-muted" v-html="modalDetail.short_description">
                    </div>
                    <div class="radio-custom mb-3">
                        <div class="d-inline-block me-1" v-for="(y, index) in modalDetail.variants" :key="'variant_'+index">
                        <input type="radio"  class="btn-check" :class="{'opacity-50':y.stock==0}" :id="index" name="variant" :value="y" v-model="modalDetail.variant_selected" @change="variantRadioChange" :disabled="y.stock==0">
                        <label :for="index"  class="btn" 
                        :class="modalDetail.variant_selected == y ? 'bg-primary border-primary text-white' : 'border-purple text-purple'">
                        {{y.label}}</label>
                        </div>
                    </div>

                    <!-- <div class="text-muted mb-0 font-size-13">
                    <del v-if="modalDetail.variant_selected.price">
                      Retail Price: {{currency}} {{convertCurrencyFormat(modalDetail.variant_selected.price || 0, true)}}
                    </del>
                    <del v-if="modalDetail.price">
                      Retail Price: {{currency}} {{convertCurrencyFormat(modalDetail.price || 0, true)}}
                    </del>
                    </div> -->

                    <div class="font-size-20 fw-semibold text-body mb-1" v-if="modalDetail.variant_selected.price">
                      <span class="fw-normal font-size-15 text-muted">{{currency}}</span> {{convertCurrencyFormat(modalDetail.variant_selected.price || 0, true)}}
                    </div>
                    <div class="font-size-20 fw-semibold text-body  mb-1"  v-if="modalDetail.price">
                      <span class="fw-normal font-size-15 text-muted">{{currency}}</span> {{convertCurrencyFormat(modalDetail.price || 0, true)}}
                    </div>
                    <!-- <div class="pricing text-dark" v-if="modalDetail.variant_selected">
                      {{convertCurrencyFormat(modalDetail.variant_selected.price || 0, true )}}</div>
                    <div v-else>{{convertCurrencyFormat((modalDetail.price || 0), true )}}</div> -->

                    <div class="text-body mb-2 d-none" v-if="modalDetail.variant_selected">
                    Points Earned: {{modalDetail.variant_selected.earn_point || modalDetail.earn_point }} Pts
                    </div>
                    <div class="font-size-14 mb-2 fw-medium" v-if="modalDetail.variants && modalDetail.variants.length>0">
                        <div class="text-primary mb-2" v-if="modalDetail.variants && modalDetail.variant_selected.stock>0">
                            <span>{{modalDetail.variant_selected.stock }}</span> Left in stock
                        </div>
                        <div class="mb-2" v-else>
                            <span class="text-danger">Out Of Stock</span>
                        </div>
                    </div>
                    <div class="font-size-14 mb-2 fw-medium" v-else>
                        <div class="text-primary mb-2" v-if="modalDetail.stock>0">
                            <span>{{modalDetail.stock }}</span> Left in stock
                        </div>
                        <div class="mb-2" v-else>
                            <span class="text-danger">Out Of Stock</span>
                        </div>
                    </div>  
                    <div class="mb-3">
                      <div class="d-flex align-items-center">
                        <div class="me-2 font-size-15">Quantity:</div>
                        <div class="input-group mb-0">
                          <button class="btn btn-primary text-white p-1 py-0" @click="decrementModal(modalDetail)" :disabled="!modalDetail.stock>0"><i class="bi bi-dash font-size-20 lh-0"></i></button>
                          <input type="text" style="max-width: 200px; " v-model="modalDetail.qty" class="form-control text-center shadow-none" :max="modalDetail.variant_selected.stock" :stock="modalDetail.stock"   @blur="quantitySingle" :disabled="!modalDetail.stock>0" @input="inputNumberOnly($event,'modalDetail','qty')">
                          <button class="btn btn-primary p-1 py-0" @click="incrementModal(modalDetail)" :disabled="!modalDetail.stock>0"><i class="bi bi-plus font-size-20 lh-0"></i></button>
                        </div>
                      </div>
                    </div>
                    <div class="d-lg-flex align-items-center mb-3 text-center">
                      <button @click="addToCart(modalDetail)" type="button" :disabled="modalDetail.qty==0 || modalDetail.stock==0 || modalDetail.variant_selected.stock==0 || addToCartLoading" class="btn btn-primary btn-lg flex-fill w-100 me-lg-1 font-size-15 mb-2 mb-lg-0">
                        <i class="bi bi-cart-plus me-1"></i> Add to Cart 
                        <span class="spinner-border spinner-border-sm ms-1" v-if="addToCartLoading" role="status"
                        aria-hidden="true"></span>
                      </button>

                      <button @click="removeFavourite(modalDetail)" v-if="modalDetail.is_favourite" :disabled="addToFavouriteLoading" type="button" class="btn btn-light btn-lg flex-fill w-100 font-size-15 mb-2 mb-lg-0 text-primary fw-medium">
                        <i class="bi bi-heart-fill text-primary me-1"></i>  Favourited 
                        <span class="spinner-border spinner-border-sm ms-1" v-if="addToFavouriteLoading" role="status"
                        aria-hidden="true"></span>
                      </button>

                      <button @click="addToFavourite(modalDetail)" v-else :disabled="addToFavouriteLoading" type="button" class="btn btn-light btn-lg flex-fill w-100 font-size-15 mb-2 mb-lg-0 text-secondary">
                        <i class="bi bi-heart me-1"></i> 
                        Add to Favourite 
                        <span class="spinner-border spinner-border-sm ms-1" v-if="addToFavouriteLoading" role="status"
                        aria-hidden="true"></span>
                      </button>
                    </div>
                  
                    <div class="tags_area" v-if="modalDetail.categories.length">
                        <span>Categories: </span>
                        <span v-for="(value, index) in modalDetail.categories" :key="index">
                          <a href="javascript:void(0)" @click="navigateToCategory(value), 
                          $refs['modal-product-detail'].hide()">{{value.title}}</a>
                        </span>  
                    </div>
                    <div class="tags_area mt-1" v-if="modalDetail.tags.length">
                        <span>Tags: </span>
                        <span v-for="(value, index) in modalDetail.tags" :key="index">
                          <a href="javascript:void(0)" @click="navigateToTag(value), 
                          $refs['modal-product-detail'].hide()">
                            {{value.title}}<span v-if="(modalDetail.tags.length-1)!==index">, </span>
                          </a>
                        </span>  
                    </div>
                    
                </div>
            </div>
            
            </div>
          </div>
          <div class="col-12 text-muted" v-if="!modalLoading">
            <div class="row">
                <div class="col-12">
                    <div class="card border product-desc-box mb-3">
                    <div class="card-body">
                        <h2 class="font-size-17 text-primary p-0 mb-3">Description</h2>
                        <div class="d-flex align-items-center mb-3">
                            <div class="me-2" v-for="(value, index) in modalDetail.tags" :key="index">
                            <a :href="value.link"><img :src="'https://nova.my/'+ value.image" alt="" class="mb-2" height="40"></a></div>
                            <div class="me-2" v-if="modalDetail.halal && modalDetail.halal.length">
                                <a v-for="(value, index) in modalDetail.halal" :href="value.pdf" class="d-block font-size-13 text-primary fw-medium" target="_blank" :key="index">
                                Halal Certificate - {{value.id}}</a>
                                <a href="https://nova.my/halal-certificate" target="_blank" class="d-block text-secondary">Check All Halal Certificate</a>
                            </div>
                        </div>
                        <div v-html="modalDetail.description" class="description_styling"></div>
                    
                    </div>
                    </div>

                    <div class="card border product-desc-box mb-3" v-if="modalDetail.ingredient_title !=='' && (modalDetail.ingredients_content!=='' || modalDetail.ingredients_table !='')">
                    <div class="card-body">
                        <h2 class="font-size-17 text-primary p-0 mb-3">{{modalDetail.ingredient_title}}</h2>
                        <div v-html="modalDetail.ingredients_content" class="description_styling" v-if="modalDetail.ingredients_content"></div>
                        <div v-html="modalDetail.ingredients_table" class="description_styling"></div>
                    </div>
                    </div>

                    <div class="card border product-desc-box mb-3" v-if="modalDetail.extra_content_1_title !=='' && modalDetail.extra_content_content_1 !==''">
                    <div class="card-body">
                        <h2 class="font-size-17 text-primary p-0 mb-3">{{modalDetail.extra_content_1_title}}</h2>
                        <div v-html="modalDetail.extra_content_content_1" class="description_styling"></div>
                    </div>
                    </div>

                    <div class="card border product-desc-box mb-3" v-if="modalDetail.extra_content_2_title !=='' && modalDetail.extra_content_content_2 !==''">
                    <div class="card-body">
                        <h2 class="font-size-17 text-primary p-0 mb-3">{{modalDetail.extra_content_2_title}}</h2>
                        <div v-html="modalDetail.extra_content_content_2" class="description_styling"></div>
                    </div>
                    </div>

                    

                    <div class="card border product-desc-box mb-3" v-if="modalDetail.long_video && modalDetail.long_video.length>0">
                    <div class="card-body">
                        <h2  class="font-size-17 text-primary p-0 mb-3">Video</h2>
                        <div class="ratio ratio-16x9 rounded-3">
                        <iframe class="rounded-3" :src="modalDetail.long_video" allowfullscreen></iframe>
                        </div>

                    </div>
                    </div>

                    <div class="card border product-desc-box mb-3 d-none" v-if="modalDetail.review && modalDetail.review.length>0">
                    <div class="card-body">
                        <h2  class="font-size-17 text-primary p-0 mb-3">Reviews</h2>
                        <ul class="list-group">
                        <li class="list-group-item align-items-center py-4" v-for="(value, index) in modalDetail.review" :key="index">
                            <div class="row align-items-center">   
                                <div class="col-12 col-lg-4 order-1 order-12 order-md-1">
                                <div class="reviewer-box">
                                    <div class="fw-medium text-secondary">{{value.name}}</div>
                                    <div class="text-muted text-nowrap text-muted">{{value.date}}</div>
                                </div>
                                </div>
                                <div class="col-12 col-lg-8 order-2 order-1 order-md-12 mb-lg-0 mb-3">
                                <div class="border-left ps-3">
                                <div>
                                    
                                    <div class="rating text-left d-inline-block">
                                    <span v-for="i in 5" :title="value.rating" :key="`rating_${i}`">
                                        <i v-if="i <= value.rating" class="checked text-orange fa fa-star"></i>
                                        <i v-else-if="(i-0.5) <= value.rating" class="checked text-orange fas fa-star-half-alt"></i>
                                        <i v-else-if="i > value.rating" class="far fa-star text-muted"></i>
                                    </span>
                                    </div>
                                </div>
                                <p class="mt-2 text-muted">“{{value.review}}”</p>
                                </div>
                                </div>
                            </div>
                        
                        </li>
                        </ul>
                    </div>
                    </div>
                </div>
            </div>
          </div>
         
          <div class="col-12">
              <hr>
              <div class="d-grid gap-2 d-md-block text-end">
                <button type="button" class="order-1 btn fw-medium btn-secondary me-md-2" @click="$refs['modal-product-detail'].hide()">Cancel</button>
              </div>
          </div>
        </div>
     </b-modal>
     <Common ref="commonFunc" />
  </div>
</template>

<script>
import appConfig from "@/app.config";
import axios from "axios";
import Swal from "sweetalert2";
import Common from "@/components/common";

/**
 * Starter page
 */
const testToken = "17429567196cf23d4c26c61c49cbb1bac38b350b15"
const testUser = "weijey@outlook.com"
export default {
  components: {
    Common,
  },
  props: {
    data: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      updateData: {
        preview_username:""
      },
      currency: appConfig.currency,
      accessToken: '',
      accessEmail: '',
      accessPhone: '',
      accessUsername: '',
      account_type: '',
      loading: false,
      modalSubmit: false,
      modalLoading: false,
      modalDetail:{
          "id": "",
          "title": "",
          "sub_title": "",
          "is_bread": false,
          "price": 0,
          "earn_point": 0,
          "stock": 20,
          "rating": 0,
          "reviewer": 0,
          "variants": [],
          "variant_selected": [],
          "tags": [],
          "categories": [],
          "halal": [],
          "images": [],
          "video": [],
          "long_video": [],
          "short_description": "",
          "ingredients_table": "",
          "extra_content_1_title": "",
          "extra_content_content_1": "",
          "extra_content_2_title": "",
          "extra_content_content_2": "",
          "review": [],
          "qty":0,
          "is_favorited": false
      },
      addToCartLoading:false,
      addToFavouriteLoading:false,
      quantity:[],
      halal:[],
      current_point:"",
    };
  },
  middleware: "authentication",
  async mounted() {
    this.reload();
  },
  created() {
    
  },
  methods: {
    navigateToCategory(value) {
      this.$emit('callParentFunction', value);
    },
    navigateToTag(value) {
      this.$emit('callParentFunction2', value);
    },
    checkIsObject(value) {
      return value !== null && typeof value === 'object' && !Array.isArray(value);
    },
    variantRadioChange() {
      if (this.checkIsObject(this.modalDetail.variant_selected)){
        if (this.modalDetail.variant_selected.stock==0){
          this.modalDetail.qty = 0
        }else{
          this.modalDetail.qty = 1
        }
      }else{
        this.modalDetail.qty = 1
      }
    },
    increment(index) {
        this.quantity[index]++
    },
    decrement(index) {
        if (this.quantity[index] === 0) {
            this.quantity[index] = 1
        }
        if (this.quantity[index] === 1) {
          console.log(this.quantity[index] === 1)
        } else {
            this.quantity[index]--
        }
    },
    incrementModal(item) {
      console.log('Item:', item);
      let max = 0;
      if (item.variants && item.variants.length > 0 && item.variant_selected) {
        max = item.variant_selected.stock;
        if (this.modalDetail.qty < max) {
          this.modalDetail.qty++;
          console.log('Incremented qty:', this.modalDetail.qty);
        } else {
          console.log('Reached max variant stock:', max);
        }
      } else {
        max = item.stock;
        if (this.modalDetail.qty < max) {
          this.modalDetail.qty++;
          console.log('Incremented qty:', this.modalDetail.qty);
        } else {
          console.log('Reached max stock:', max);
        }
      }
      console.log('final' , this.modalDetail.qty)
    },
    decrementModal(item) {
      console.log(item)
        if (this.modalDetail.qty=== 0) {
            this.modalDetail.qty= 1
        }
        if (this.modalDetail.qty== '') {
            this.modalDetail.qty= 1
        }
        if (this.modalDetail.qty== null) {
            this.modalDetail.qty= 1
        }
        if (this.modalDetail.qty=== 1) {
          console.log(this.modalDetail.qty=== 1)
        } else {
            this.modalDetail.qty--
        }
    },
    quantitySingle: function(event) {
        var value = this.modalDetail.qty
        var variant_stock = event.target.getAttribute("max")
        var stock = event.target.getAttribute("stock")
        var max = 0
        if (variant_stock !== null) {
            max = parseInt(variant_stock)
        } else {
            max = parseInt(stock)
        }

        value = value.replace(/[^0-9.-]/g, '').replace(/(\..*?)\..*/g, '$1');
        if (value > max) {
            this.modalDetail.qty= 1
        }

        console.log(value)
        if (value.length == 0) {
            this.modalDetail.qty= 1
        }
        if (value == '') {
            this.modalDetail.qty= 1
        }
        if (value < 1) {
            this.modalDetail.qty= 1
        }
    },

    quantityMultiple: function(event) {
        var index = event.target.getAttribute("index")
        var value = event.target.getAttribute("val")
        console.log(value, index)
        value = value.replace(/[^0-9.-]/g, '').replace(/(\..*?)\..*/g, '$1');
        if (value.length == 0) {
            this.quantity[index] = 1
        }
        if (value == '') {
            this.quantity[index] = 1
        }
        if (value < 1) {
            this.quantity[index] = 1
        }
    },
    youtubeEmbed(url) {
        // Extract the video ID from the URL
        let video_id = '';
        if (url.indexOf('youtube.com') !== -1) {
            if (url.indexOf('/watch?v=') !== -1) {
                video_id = url.split('/watch?v=')[1];
            } else if (url.indexOf('/shorts/') !== -1) {
                video_id = url.split('/shorts/')[1];
            }
        } else if (url.indexOf('youtu.be') !== -1) {
            video_id = url.split('.be/')[1];
        } else {
            video_id = url;
        }
        const YtUrl = 'https://www.youtube.com/embed/' + video_id

        return YtUrl;
    },
    inputNumberOnly(event, parentModel, childModel) {
      const numericValue = event.target.value.replace(/[^0-9.]/g, '');
      this[parentModel][childModel] = numericValue
    },
    inputNumberOnlyV2(event, parentModel, childModel) {
      var numericValue = event.target.value.replace(/[^0-9.]/g, '');
      this[parentModel][childModel] = numericValue
      if(numericValue.length > 2){
        var val = numericValue.replace(/[^0-9]/g, '');
        console.log(val);
        val = val.substr(0, val.length-2)+"."+val.substr(-2);
        this[parentModel][childModel] = val;
      }
    },
    inputNumberOnlyV3(event, parentModel, childModel, index) {
      var numericValue = event.target.value.replace(/[^0-9.]/g, '');
      //this[parentModel][childModel] = numericValue
      this[parentModel].contract_selected[index][childModel] = numericValue
      if(numericValue.length > 2){
        var val = numericValue.replace(/[^0-9]/g, '');
        console.log(val);
        val = val.substr(0, val.length-2)+"."+val.substr(-2);
        this[parentModel].contract_selected[index][childModel] = val;
      }
    },
    convertCurrencyFormat(value, show00) {
      if (show00 == true) {
        return this.$refs.commonFunc.convertCurrencyFormat(value)
      } else {
        // Assuming this.transactionLimit is defined in your component's data
        return this.$refs.commonFunc.convertCurrencyFormat(value).replaceAll(".00", "");
      }
    },
    
    showProductDetail() {
      this.$refs['modal-product-detail'].show()
      this.accessToken = this.data.assessToken;
      this.accessUsername = this.data.accessUsername;
      this.updateData = this.data.updateData;
      this.getProductDetail(this.data.updateData.id);
    },
    getProductDetail(id){
      this.$Progress.start();
      this.modalLoading = true;
      var bodyFormData = new FormData();
      
      // bodyFormData.append("accessToken", this.accessToken);
      // bodyFormData.append("accessUsername", this.accessUsername);
      bodyFormData.append("token", 'apPCRP2fn92JeqSu8cS6DXrh5reVZD');
      bodyFormData.append("accessToken", testToken);
      bodyFormData.append("email", testUser);
      bodyFormData.append("productId", id);
      axios({
          method: "post",
          //url: appConfig.APIHost + 'controller/user/updateUserPassword',
          url: appConfig.WebsiteHost + 'controller/product/getProductDetailsById',
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response)=>{
          
          var resData = response.data;
          if (resData.status == 200) {
            console.log(resData)
            var imagesDataset=[]
            var data = resData.product_details;
              
              if (data.id == undefined) {
                  return false
              }
              this.modalDetail = {
                id: data.id || null,
                title: data.title || "",
                sub_title: data.sub_title || "",
                is_bread: data.is_bread || false,
                price: data.price || 0,
                earn_point: data.earn_point || 0,
                stock: data.stock || 0,
                rating: data.rating || 0,
                reviewer: data.reviewer || 0,
                variants: data.variants || [],
                variant_selected: data.variant_selected || {},
                tags: data.tags || [],
                categories: data.categories || [],
                halal: data.halal || [],
                images: data.images || [],
                video: data.video || [],
                long_video: data.long_video || [],
                short_description: data.short_description || "",
                description:data.description.replaceAll('<p>&nbsp;</p>','').replaceAll('<p class="ql-align-justify">&nbsp;</p>','').replaceAll('<p><br></p>','') || "",
                ingredient_title: data.ingredient_title || "",
                ingredients_content: data.ingredients_content.replaceAll('<p>&nbsp;</p>','').replaceAll('<p class="ql-align-justify">&nbsp;</p>','').replaceAll('<p><br></p>','') || "",
                ingredients_table: data.ingredients_table || "",
                extra_content_1_title: data.extra_content_1_title || "",
                extra_content_content_1: data.extra_content_content_1 || "",
                extra_content_2_title: data.extra_content_2_title || "",
                extra_content_content_2: data.extra_content_content_2 || "",
                review: data.review || [],
                qty: 0,
                is_favorited: data.is_favorited || false
              }
              
              if (data.variants.length > 0) {
                  this.modalDetail.variant_selected = data.variants[0]
                  if (this.modalDetail.variant_selected.stock == 0) {
                      this.modalDetail.qty = 0
                  } else {
                      this.modalDetail.qty = 1
                  }
              } else {
                  if (this.modalDetail.stock == 0) {
                      this.modalDetail.qty = 0
                  } else {
                      this.modalDetail.qty = 1
                  }
              }
              //this.modalDetail.images = item.images


              if (data.long_video.length > 0) {
                  const ytVideo = data.long_video[0]
                      .link // "https://www.youtube.com/watch?v=YT0X1gZ3k5s" //data.long_video
                  this.modalDetail.long_video = this.youtubeEmbed(ytVideo)
              }

              if (data.images.length > 0) {
                  console.log(data.images)
                  data.images.forEach(element => {
                      imagesDataset.push({
                          img: element.image,
                          thumb: element.image
                      })
                  });
              }

              if (data.video.length > 0) {
                  console.log(data.video)
                  data.video.forEach(element => {
                      imagesDataset.splice(1, 0, {
                          img: element.cover,
                          thumb: element.cover,
                          video: element.link
                      });
                  });
              }
              var tags = data.tags
              for (let i = 0; i < tags.length; i++) {
                  if (tags[i].title === "Halal") {
                      const halalTag = tags.splice(i, 1)[0];
                      tags.push(halalTag);
                      break;
                  }
              }
              this.modalDetail.tags = tags
              this.modalDetail.is_favourite = data.is_favorited || false
          } 
          else if (resData.status == 440){
            Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
              localStorage.clear();
              this.$router.push({
                  path: "/login",
            });
          }
          else {
              Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
          }
          this.modalLoading = false;
          this.$Progress.finish();
        })
        .catch((error)=> {
            this.modalLoading = false;
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire(
              {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
      });
    },
    addToCart(value){
      this.$Progress.start();
      this.addToCartLoading = true;
      var bodyFormData = new FormData();
      // bodyFormData.append("accessToken", this.accessToken);
      // bodyFormData.append("accessUsername", this.accessUsername);
      bodyFormData.append("accessToken", testToken);
      bodyFormData.append("email", testUser);
      bodyFormData.append("productID", value.id);
      bodyFormData.append("qty", value.qty);
      bodyFormData.append("variantID", value.variant_selected.id || '');
      var variantName = ""
      if (value.variant_selected.id !== undefined) {
          //if got variant
          variantName = `(${value.variant_selected.label})`
      } else {
          variantName = ''
      }

      axios({
          method: "post",
          //url: appConfig.APIHost + 'controller/user/updateUserPassword',
          url: appConfig.WebsiteHost + 'controller/product/addToCart',
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response)=>{
          
          var resData = response.data;
          if (resData.status == 200) {
            Swal.fire({
                title: 'Success',
                text: `${value.title} ${variantName} has been added to your shopping cart`,
                icon: 'success',
                showCancelButton: false,
                confirmButtonText: 'View Cart'
            }).then((result) => {
                //totalCartNumber = totalCartNumber + (1 * item.qty)
                var updateData = {
                  "cart": resData.data.total_cart,
                };
                this.$refs.commonFunc.updateData(JSON.stringify(updateData));
                this.$emit('callParentFunction3');
                value.qty = 1
                if (result.isConfirmed) {
                    console.log('go to cart');
                    this.$router.push({name: 'cart'}).catch(() => { });
                }
                this.$refs['modal-product-detail'].hide()

            })
          } 
          else if (resData.status == 440){
            Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
              localStorage.clear();
              this.$router.push({
                  path: "/login",
            });
          }
          else {
              Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
          }
          this.addToCartLoading = false;
          this.$Progress.finish();
        })
        .catch((error)=> {
            this.addToCartLoading = false;
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire(
              {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
      });
    },
    addToFavourite(value){
      this.$Progress.start();
      this.addToFavouriteLoading = true;
      var bodyFormData = new FormData();
      // bodyFormData.append("accessToken", this.accessToken);
      // bodyFormData.append("accessUsername", this.accessUsername);
      bodyFormData.append("accessToken", testToken);
      bodyFormData.append("email", testUser);
      bodyFormData.append("productId", value.id);
      axios({
          method: "post",
          //url: appConfig.APIHost + 'controller/user/updateUserPassword',
          url: appConfig.WebsiteHost + 'controller/account/addFavoriteProduct',
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response)=>{
          
          var resData = response.data;
          if (resData.status == 200) {
            var updateData = {
              "favourite": resData.data.total_favourited,
            };
            this.$refs.commonFunc.updateData(JSON.stringify(updateData));
            this.$emit('callParentFunction3');
            value.is_favourite = true;
          } 
          else if (resData.status == 440){
            Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
            //   localStorage.clear();
            //   this.$router.push({
            //       path: "/login",
            // });
          }
          else {
              Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
          }
          this.addToFavouriteLoading = false;
          this.$Progress.finish();
        })
        .catch((error)=> {
            this.addToFavouriteLoading = false;
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire(
              {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
      });
    },
    removeFavourite(value){
      this.$Progress.start();
      this.addToFavouriteLoading = true;
      var bodyFormData = new FormData();
      // bodyFormData.append("accessToken", this.accessToken);
      // bodyFormData.append("accessUsername", this.accessUsername);
      bodyFormData.append("accessToken", testToken);
      bodyFormData.append("email", testUser);
      bodyFormData.append("productId", value.id);
      axios({
          method: "post",
          //url: appConfig.APIHost + 'controller/user/updateUserPassword',
          url: appConfig.WebsiteHost + 'controller/account/removeFavouriteProduct',
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response)=>{
          
          var resData = response.data;
          if (resData.status == 200) {
            var updateData = {
              "favourite": resData.data.total_favourited,
            };
            this.$refs.commonFunc.updateData(JSON.stringify(updateData));
            this.$emit('callParentFunction3');
            value.is_favourite = false;
          } 
          else if (resData.status == 440){
            Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
              localStorage.clear();
              this.$router.push({
                  path: "/login",
            });
          }
          else {
              Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
          }
          this.addToFavouriteLoading = false;
          this.$Progress.finish();
        })
        .catch((error)=> {
            this.addToFavouriteLoading = false;
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire(
              {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
      });
    },
    resetModal() {
      this.modalDetail={
          "id": "",
          "title": "",
          "sub_title": "",
          "is_bread": false,
          "price": 0,
          "earn_point": 0,
          "stock": 20,
          "rating": 0,
          "reviewer": 0,
          "variants": [],
          "variant_selected": [],
          "tags": [],
          "categories": [],
          "halal": [],
          "images": [],
          "video": [],
          "long_video": [],
          "short_description": "",
          "ingredients_table": "",
          "extra_content_1_title": "",
          "extra_content_content_1": "",
          "extra_content_2_title": "",
          "extra_content_content_2": "",
          "review": [],
          "qty":0,
          "is_favorited": false
      }
      this.modalSubmit = false;
      this.modalLoading = false;
    },
    async reload() {
      try {
        await this.$nextTick(); // Wait for the component to be fully mounted
        const commonFunc = this.$refs.commonFunc;
        if (commonFunc && commonFunc.getFullData) {
          const data = await commonFunc.getFullData();
          this.account_type = data.account_type;
          
        } else {
          // Handle the case when commonFunc or getFullData is undefined
          // Perform appropriate actions or show a fallback message to the user
        }
      } catch (error) {
        // Handle the error silently without propagating it further
      }
    },
  }
};
</script>
<style scoped>
    label {
      margin-bottom: 5px;
    }
  
    .custom-table td .row {
      min-width: 220px;
  
    }
  
    .description_styling h1,
    .description_styling h2,
    .description_styling h3,
    .description_styling h4,
    .description_styling h5 {
      letter-spacing: normal;
      margin-bottom: 10px;
      color: #555;
    }
  
    .description_styling strong {
      color: #555;
    }
  
    .description_styling li {
      font-size: 14px
    }
  
    .description_styling p span {
      color: #555 !important;
      font-size: 14px;
      font-family: 'avenir', system-ui !important;
      line-height: 1.5 !important;
    }
  
    .description_styling {
      overflow-x: auto;
    }
  
    .description_styling table {
      border: none !important;
      white-space: nowrap !important;
    }
  
    .description_styling table tr td {
      border: none !important;
      padding: 0px 5px;
    }
</style>